.top-content {
    width: 100%;
    padding: 0;
}
.top-content {
    background: -webkit-linear-gradient(0deg, rgba(13,28,39,0.90) 10%, rgba(13,28,39,0.20) 100%);
}
h1.animate__animated.animate__lightSpeedInLeft {
    position: relative;
    display: block;
    color: #fff;
    font-size: 100px;
    line-height: 100px;
    font-weight: 800;
    margin-bottom: 3px;
    text-transform: uppercase;
}
h2.animate__animated.animate__fadeInUp {
    font-size: 48px;
    line-height: 65px;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
}
p.animate__animated.animate__fadeInUp {
    position: relative;
    display: inline-block;
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    color: #fff;
    margin-bottom: 41px;
    opacity: 0;
}
.pattern-layer.shape9 {
    background-image: url(https://res.cloudinary.com/dll4d2yu7/image/upload/v1697819688/immgoCdn/shape-9_ccw6m1.png);
}
.top-content .carousel {
    margin-bottom: 0px;
}
.top-content .carousel-control-prev {
    border-bottom: 0;
}
.top-content .carousel-control-next {
    border-bottom: 0;
}

@media (max-width: 767px) {
h1, h2 {
    font-size: 22px;
    line-height: 30px;
}

.top-content .carousel-indicators {
    display: none;
}
}
.top-content .carousel-item {
    height: 100vh;
    min-height: 400px;
}
.top-content .carousel-item img {
    height: 100%;
    object-fit: cover;
}

@media (max-width: 575px) {

.top-content h1, .top-content h2, .top-content h3 {
    font-size: 18px;
}
}

@media (max-width: 575px) {
.top-content .carousel-item {
    max-height: 400px;
}
}
/*---------- SLIDE CAPTION ----------*/
.slide_style_left {
    text-align: left !important;
	left: 0;
    top: 35%;
    margin: auto;
    padding: 10px;
    position: absolute;
    text-align: left;
    padding: 10px 85px;
}
.slide_style_left {
    background: -webkit-linear-gradient(0deg, rgba(13,28,39,0.90) 10%, rgba(13,28,39,0.20) 100%);
}
.slide_style_left {
    text-align: left !important;
    left: -1px;
    top: 3  6%;
    margin: auto;
    padding: 10px;
    position: absolute;
    text-align: left;
    padding: 10px 85px;
    width: 100%;
}
.slide_style_right {
    text-align: right !important;
    top: 25%;
    right: 0;
    margin: auto;
    padding: 10px;
    position: absolute;
    text-align: left;
    padding: 10px 85px;
}
.slide_style_bottom {
    text-align: center !important;
	left: 0;
    bottom:15%;
    right: 0;
    margin: auto;
    padding: 10px;
    position: absolute;
    text-align: left;
    padding: 10px 85px;
}

.slide-text > h1 {
    padding: 0;
    color: #ffffff;
    font-size: 70px;
    font-style: normal;
    line-height: 84px;
    margin-bottom: 30px;
    letter-spacing: 1px;
    display: inline-block;
    -webkit-animation-delay: 0.7s;
    animation-delay: 0.7s;
}

@media (min-width: 320px) and (max-width: 425px) {
    h1.animate__animated.animate__lightSpeedInLeft {
    font-size: 35px;
}
}
@media (min-width: 320px) and (max-width: 425px) {
    h2.animate__animated.animate__fadeInUp{
        font-size: 15px;
        line-height: 15px;
        margin-top: -30px;
}
}
@media (min-width: 320px) and (max-width: 425px) {
p.animate__animated.animate__fadeInUp{
    font-size: 15px;
    line-height: 20px;
    margin-top: 15px;
}
}
	.slide-heading{
		font-size: 40px;
    font-weight: bold;
    color: #f4ac0c;
	 -webkit-animation-delay: 0.7s;
    animation-delay: 0.7s;
	}
	.slide-heading-1{
	font-size: 40px;
    font-weight: bold;
    color: #fff;
	-webkit-animation-delay: 0.7s;
    animation-delay: 0.7s;
	}
.slide-text > p {
    padding: 0;
    color: #ffffff;
    font-size: 20px;
    line-height: 24px;
    font-weight: 300;
    margin-bottom: 40px;
    letter-spacing: 1px;
    -webkit-animation-delay: 1.1s;
    animation-delay: 1.1s;
}
.slide-text > a.btn-default, .slide-text > a.btn-warning, .slide-text > a.btn-danger {
    color: #000;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    margin-right: 10px;
    text-align: center;
    padding: 17px 30px;
    white-space: nowrap;
    letter-spacing: 1px;
    display: inline-block;
    border: none;
    text-transform: uppercase;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    -webkit-transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
    transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
}
.slide-text > a.btn-primary {
    color: #ffffff;
    cursor: pointer;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    margin-left: 10px;
    text-align: center;
    padding: 17px 30px;
    white-space: nowrap;
    letter-spacing: 1px;
    background: #00bfff;
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    border: none;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    -webkit-transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
    transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
}
.slide-text > a:hover, .slide-text > a:active {
    color: #ffffff;
    background: #222222;
    -webkit-transition: background 0.5s ease-in-out, color 0.5s ease-in-out;
    transition: background 0.5s ease-in-out, color 0.5s ease-in-out;
}
