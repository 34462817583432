section.page-title.page-title9 {
    background-image: url(https://res.cloudinary.com/dll4d2yu7/image/upload/v1697902515/page-title-9_hrm2hu.jpg);
}

.pattern-layer.shape-4 {
    background-image: url(https://res.cloudinary.com/dll4d2yu7/image/upload/v1697904351/shape-4_t4nptg.png);
}
.pattern-layer.shape-8 {
    background-image: url(https://res.cloudinary.com/dll4d2yu7/image/upload/v1697905869/shape-8_vdpkas.png);
}
.bg-layer.contact-bg {
    background-image: url(https://res.cloudinary.com/dll4d2yu7/image/upload/v1697905869/contact-bg_dgclfw.jpg);
}