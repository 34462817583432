section.page-title.page-title {
    background-image: url(https://res.cloudinary.com/dll4d2yu7/image/upload/v1697815669/immgoCdn/page-title_xxjvxc.jpg);
}
.boxed_wrapper.about {
    position: relative;
    margin: 94px auto;
    overflow: hidden !important;
    width: 100%;
    min-width: 300px;
}
.inner-container.mission-bg2 {
    background-image: url(https://res.cloudinary.com/dll4d2yu7/image/upload/v1697816611/immgoCdn/mission-bg-2_j851lp.jpg);
}

section.mission-section.mission-bg {
    background-image: url(https://res.cloudinary.com/dll4d2yu7/image/upload/v1697816757/immgoCdn/mission-bg_jvmx19.jpg);
}